.cookie-block {
	bottom: 20px;
    font-size: 14px;
	left: 0;
	padding: 0 16px;
	position: fixed;
	width: 100%;
	z-index: 1000;
	.cookie-container {
        align-items: center;
        background-color: rgba(90, 228, 170, 0.95);
        border-radius: 8px;
        color: $brand4;
        display: flex;
        flex-direction: column;
		margin: 0 auto;
		max-width: 992px;
        position: relative;
        overflow: hidden;
		width: 100%;
        padding: 16px;
        @include media-breakpoint-up(sm) {
            flex-direction: row;
            padding: 16px 24px;
        }
        @include media-breakpoint-up(lg) {
            padding: 16px 32px;
        }
    }
	a {
        color: $brand4;
        border-color: $brand4;
        font-size: 14px;
		&:hover,
		&:focus {
			color: $brand1;
        }
    }
	.close-cookie {
		background: transparent;
		border: 0;
		color: #fff;
        cursor: pointer;
		height: 32px;
		width: 32px;
		text-align: center;
		position: absolute;
		top: 12px;
        font-size: 20px;
		right: 0;
		&:hover {
			color: $green1;
        }
		svg {
			width: 12px;
        }
    }
    .text {
        @include media-breakpoint-up(md) {
            max-width: 656px;
            margin-right: 48px;
        }
    }
    .accept {
        display: flex;
        align-items: center;
        margin-top: 16px;
        @include media-breakpoint-up(sm) {
            margin-top: 0;
            flex-direction: column;
        }
        @include media-breakpoint-up(md) {
            margin-left: auto;
        }
    }
    .accept-block {
        order: 1;
        @include media-breakpoint-up(sm) {
            order: 0;
            margin-bottom: 16px;
        }
    }
    .decline-block {
        margin-right: 44px;
        order: 0;
        @include media-breakpoint-up(sm) {
            margin-right: 0;
            order: 1;
        }
    }
    .accept-cookie {
        background: $brand1;
        border: 0;
        color: #fff;
        padding: 0 40px;
        min-width: 0;
        &:hover {
            background: lighten($brand1, 6%);
        }
    }
    .decline-cookie {
        border: 0;
        background: transparent;
        color: $brand5;
        padding: 0;
        outline: 0;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}