/*
 *  Owl Carousel - Animate Plugin
 */
 /*****************************************/
 /* Soft Scale */
 /*****************************************/
 .owl-animate {
     .owl-stage{
        transform: none !important;
        width: 100% !important;
        position: relative;
        border: none;
        overflow: hidden;
        display: block;
    }
     .owl-item {
         width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        &:not(:first-child) {
            position: absolute;
            top: 0 !important;
            left: 0 !important;
        }
         &.active{
            z-index: 20;
            opacity: 1;
        }
     }
	 .animated {
        animation-duration: 0.4s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
        .animate-element {
            animation-duration: 0.4s;
            animation-fill-mode: forwards;
            animation-timing-function: ease-in-out;
        }
     }
	 .owl-animated-in {
 		z-index: 0;
 	}
 	.owl-animated-out {
 		z-index: 1;
 	}
 }

 .fadeOutNext,
 .fadeOutPrev {
     transition: opacity .4s ease;
	 .animate-element {
		 animation-name: slidefadeOut;
         animation-duration: 0.4s;
         animation-fill-mode: forwards;
	 }
 }

 .fadeInNext {
	 .animate-element {
		 animation-name: fadeInRight;
	 }
 }

 .fadeInPrev {
	 .animate-element {
		 animation-name: fadeInLeft;
	 }
 }
.slider.owl-carousel {
   .animated {
	   animation-duration: 300ms;
   }
}
/* .app-slider.owl-carousel {
   .animated {
	   animation-duration: 200ms;
   }
   .anim {
	   animation-duration: 600ms;
	   animation-fill-mode: both;
   }
} */
.about-slider.owl-carousel {
   .animated {
	   animation-duration: 200ms;
   }
   .anim {
	   animation-duration: 600ms;
	   animation-fill-mode: both;
   }
}

@keyframes slidefadeOut {
	0% {
		opacity: 1;
	}
    50% {
        opacity: 0;
    }
	100% {
		opacity: 0;
	}
}

@keyframes fadeOutLeft {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
		transform: translate3d(-30px, 0, 0);
	}
}

.fadeOutLeft {
	animation-name: fadeOutLeft;
}

@keyframes fadeInRight {
	from {
		opacity: 0;
		transform: translate3d(30px, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInRight {
	animation-name: fadeInRight;
}

@keyframes fadeInLeft {
	from {
		opacity: 0;
		transform: translate3d(-30px, 0, 0);
	}

to {
	opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.fadeInLeft {
	animation-name: fadeInLeft;
}
