@import "ajax/functions";
@import "ajax/variables";
@import "ajax/mixins";
@import "ajax/cookie";

/* roboto-300 - latin */
@font-face {
    font-family: 'Roboto Light';
    font-style: normal;
    font-weight: 300;
    src: local('Roboto Light'), local('Roboto-Light'),
       url('../fonts/Roboto/roboto-300.woff2') format('woff2'),
       url('../fonts/Roboto/roboto-300.woff') format('woff');
    font-display: swap;
}
/* roboto-regular - latin */
@font-face {
    font-family: 'Roboto Regular';
    font-style: normal;
    font-weight: 400;
    src: local('Roboto'), local('Roboto-Regular'),
       url('../fonts/Roboto/roboto-regular.woff2') format('woff2'),
       url('../fonts/Roboto/roboto-regular.woff') format('woff');
    font-display: swap;
}
/* roboto-500 - latin */
@font-face {
    font-family: 'Roboto Medium';
    font-style: normal;
    font-weight: 500;
    src: local('Roboto Medium'), local('Roboto-Medium'),
       url('../fonts/Roboto/roboto-500.woff2') format('woff2'),
       url('../fonts/Roboto/roboto-500.woff') format('woff');
    font-display: swap;
}

@at-root {
    @-ms-viewport { width: device-width; }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -ms-overflow-style: scrollbar;
}
article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}
body {
    background-color: $gray2;
    color: $brand1;
    display: flex;
    font-family: "Roboto Regular", sans-serif;
    font-size: 16px;
    font-weight: 400;
    flex-direction: column;
    line-height: $line-height;
    min-height: 101vh;
    margin: 0;
    text-align: left;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}

p {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 0;
}

a {
    color: $link-color;
    text-decoration: $link-decoration;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    @include transition($link-transition);
    @include hover {
        color: $link-hover-color;
        text-decoration: $link-decoration;
    }
}

img {
    display: inline-block;
    vertical-align: middle;
    border-style: none;
    max-width: 100%;
    &[data-src],
    &[data-srcset] {
        min-height: 1px;
    }
}

svg:not(:root) {
    overflow: hidden;
}
.svg-icon {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
    width: 1em;
}
input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

video {
    outline: 0;
}
h2,h3,h4 {
    line-height: 1.2;
}
h2 {
    font-family: 'Roboto Medium';
    font-size: 32px;
    font-weight: 500;
    margin: 0 0 32px;
    @include media-breakpoint-up(sm) {
        font-size: 40px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 48px;
    }
}
h3 {
    font-family: 'Roboto Medium';
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 32px;
    @include media-breakpoint-up(lg) {
        font-size: 32px;
    }
}
h4 {
    font-family: 'Roboto Medium';
    font-size: 24px;
    font-weight: 500;
    margin: 0 0 8px;
}
h1 {
    font-family: 'Roboto Regular';
    font-size: 24px;
    font-weight: 400;
    line-height: 0.9;
    margin: 32px 0;
    @include media-breakpoint-up(sm) {
        margin: 48px 0;
    }
    @include media-breakpoint-up(md) {
        font-size: 32px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 62px;
        margin: 64px 0;
    }
}
.font-light {
    font-family: 'Roboto Light';
    font-weight: 300;
}
.text-white {
    color: #fff;
}
.btn {
    align-items: center;
    border: 0;
    border-radius: 24px;
    color: $brand1;
    display: inline-flex;
    height: 40px;
    font-family: 'Roboto Medium';
    font-weight: 500;
    font-size: 14px;
    min-width: 224px;
    padding: 0;
    justify-content: center;
    white-space: nowrap;
    user-select: none;
    transition: background .2s ease-in-out;
    width: fit-content;
    &:focus {
        outline: 0;
    }
    &.btn-primary {
        background-color: $green1;
        &:hover,
        &:focus {
            background: darken($green1,6%);
            color: $brand1;
        }
    }
    &.btn-border {
        border: 1px solid $green1;
        color: $green1;
        transition: background .2s ease-in-out, color .2s ease-in-out;
        &:hover,
        &:focus {
            background: $green1;
            color: $brand1;
        }
    }
}
.container {
    width: 100%;
    max-width: 1536px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 16px;
    padding-left: 16px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -16px;
    margin-left: -16px;
    &.no-gutter {
        margin-right: 0;
        margin-left: 0;
    }
}
.text-center {
    text-align: center;
}
.bg-gray1 {
    background: $gray1;
}
.bg-brand1 {
    background: $brand1;
}
.bg-white {
    background: #fff;
}
.block-p96 {
    padding: 96px 0;
}
.block-p128 {
    padding: 96px 0;
    @include media-breakpoint-up(xl) {
        padding: 128px 0;
    }
}
.mb-64 {
    margin-bottom: 64px;
}
.mb-48 {
    margin-bottom: 48px;
}
.icons-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 48px 0 0;
    padding: 0;
    @include media-breakpoint-up(md) {
        margin: 64px 0 0;
    }
    li {
        align-items: center;
        display: flex;
        font-size: 16px;
    }
}
.icons-list__type1 {
    li {
        padding: 16px 0;
        &:not(:last-child) {
            margin-right: 64px;
        }
    }
    .icon {
        margin-right: 16px;
    }
}
.icons-list__type2 {
    align-items: flex-start;
    li {
        margin-bottom: 32px;
        flex: 0 0 100%;
        max-width: 100%;
        @include media-breakpoint-up(md) {
            flex-direction: column;
            flex: 0 0 224px;
            max-width: 224px;
            margin-bottom: 48px;
            justify-content: center;
            text-align: center;
            width: 224px;
        }
    }
    .icon {
        margin-right: 24px;
        @include media-breakpoint-up(md) {
            margin-bottom: 24px;
            margin-right: 0;
        }
    }
}
.icons-list__type3 {
    li {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 48px;
        padding: 0 16px;
        @include media-breakpoint-up(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .icon {
        margin-right: 24px;
    }
}
.round-icon {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 88px;
    justify-content: center;
    overflow: hidden;
    width: 88px;
    flex: 0 0 88px;
    max-width: 88px;
}

.header {
    background: $brand1;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    @include media-breakpoint-down(sm) {
        display: block !important;
    }
}
.header-container {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: space-between;
    padding: 0 0 0 16px;
    @media (min-width: 1200px) {
        padding: 0 0 0 48px;
    }
}
.logo {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
    svg {
        fill: #fff;
        width: 76px;
    }
}
.header-btn {
    background: $green1;
    color: $brand1;;
    font-size: 14px;
    height: 60px;
    line-height: 60px;
    letter-spacing: .9px;
    margin-left: auto;
    text-transform: uppercase;
    padding: 0 16px;
    transition: background .2s ease-in-out;
    &:hover,
    &:focus {
        background: darken($green1,6%);
        color: #181818;
    }
    @media (min-width: 992px) {
        font-size: 18px;
        padding: 0 24px;
    }
}

.block1 {
    align-items: center;
    background: #000;
    padding-top: 60px;
    position: relative;
    width: 100%;
    &:after {
        background: rgba(#000,.1);
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
    }
    @include media-breakpoint-up(sm) {
        display: flex;
        min-height: 100vh;
        padding-top: 0;
    }
    @include media-breakpoint-up(xl) {
        height: 100vh;
        max-height: 1080px;
    }
    .block1-media {
        height: 324px;
        position: relative;
        width: 100%;
        @include media-breakpoint-up(sm) {
            height: 100%;
            position: absolute;
        }
    }
    video {
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    img {
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
        position: absolute;
        width: 100%;
    }
    .content {
        max-width: 752px;
        margin: 0 auto;
        padding: 48px 16px 96px;
        text-align: center;
        z-index: 1;
        @include media-breakpoint-up(sm) {
            padding: 100px 16px;
        }
        @include media-breakpoint-up(lg) {
            margin: 0;
            text-align: left;
        }
    }
    .logo-icon {
        width: 152px;
        @include media-breakpoint-up(sm) {
            width: 227px;
        }
        @include media-breakpoint-up(lg) {
            width: 350px;
        }
    }
    .text {
        font-size: 16px;
        max-width: 647px;
        margin: 0 auto;
        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 20px;
            margin: 0;
        }
    }
    .btn-block {
        margin-top: 48px;
    }
}
.block2 {
    padding: 96px 0 0;
    @include media-breakpoint-up(xl) {
        padding: 96px 0 32px;
    }
    h2 {
        margin-bottom: 64px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 72px;
        }
    }
}
.promo-card {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
        margin-bottom: 64px;
    }
    @include media-breakpoint-up(xxl) {
        padding: 480px 48px 80px;
    }
    &.open {
        &:before {
            background: rgba($brand1, .8);
            content: '';
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            width: 100%;
            z-index: 10;
        }
    }
    .content {
        position: relative;
        z-index: 2;
    }
    .media-block {
        display: flex;
        align-items: center;
        height: 320px;
        padding: 24px;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        @include media-breakpoint-up(lg) {
            height: 350px;
        }
        @include media-breakpoint-up(xl) {
            height: 400px;
        }
        img, video {
            max-width: 95%;
            @include media-breakpoint-up(sm) {
                max-width: 450px;
            }
        }
    }
    h3 {
        font-family: 'Roboto Regular';
        font-weight: 400;
    }
}
.card1 {
    display: flex;
    flex: 0 0 100%;
    max-width: 100%;
    flex-direction: column;
    @include media-breakpoint-between(md,lg) {
        flex-direction: row;
    }
    @include media-breakpoint-up(xl) {
        padding: 400px 24px 64px;
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
        flex-direction: column;
    }
    @include media-breakpoint-up(xxl) {
        padding: 400px 48px 64px;
    }
    &:after {
        top: 200px;
        content: '';
        height: 264px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        @include media-breakpoint-between(md,lg) {
            display: none;
        }
        @include media-breakpoint-up(xl) {
            top: 28%;
            height: 570px;
        }
    }
    .preview-block {
        height: 320px;
        position: relative;
        width: 100%;
        @include media-breakpoint-up(md) {
            height: auto;
            width: auto;
        }
        @include media-breakpoint-between(md,lg) {
            flex: 0 0 328px;
            max-width: 328px;
        }
        @include media-breakpoint-up(xl) {
            height: 400px;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
        .case-video, img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    .content {
        padding: 0 16px 64px;
        @include media-breakpoint-up(md) {
            padding: 48px 24px;
        }
        @include media-breakpoint-up(xl) {
            padding: 0;
        }
    }
    .text {
        @include media-breakpoint-up(md) {
            min-height: 120px;
        }
    }
    .video-btn {
        left: 16px;
        top: -72px;
        position: absolute;
        @include media-breakpoint-up(md) {
            left: 0;
        }
        @include media-breakpoint-up(xl) {
            left: 0;
        }
    }
}
.card2 {
    padding: 320px 0 64px;
    @include media-breakpoint-up(lg) {
        padding: 350px 0 64px;
        flex: 0 0 50%;
        max-width: 50%;
    }
    @include media-breakpoint-up(xl) {
        padding: 400px 0 64px;
    }
    .content {
        padding: 0 16px;
        @include media-breakpoint-up(md) {
            padding: 0 24px;
        }
        @include media-breakpoint-up(xxl) {
            padding: 0 48px;
        }
    }
    &:after {
        bottom: 0;
        top: auto;
        content: '';
        height: 324px;
        left: 0;
        position: absolute;
        width: 100%;
        z-index: 1;
        @include media-breakpoint-up(md) {
            top: 11%;
            height: 570px;
        }
        @include media-breakpoint-up(lg) {
            height: 400px;
            top: 35%;
        }
        @include media-breakpoint-up(xxl) {
            height: 570px;
            top: 28%;
        }
    }
    .video-btn {
        left: 16px;
        top: -72px;
        position: absolute;
        @include media-breakpoint-up(md) {
            left: 24px;
        }
        @include media-breakpoint-up(xl) {
            left: 48px;
        }
    }
}
.cases-type1 {
    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
}
.cases-type2 {
    @include media-breakpoint-up(md) {
        margin-top: 64px;
    }
    @include media-breakpoint-up(xl) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;
    }
}
.case-img,
.case-video {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
}
.case1 {
    background: #050c15;
    &:after {
        background-image: linear-gradient(to top, #050c15, #070c0f 65%, rgba(6, 22, 35, 0));
    }
}
.case2 {
    background: #464945;
    &:after {
        background-image: linear-gradient(to top, #464945, #464945 65%, rgba(70, 73, 69, 0));
    }
}
.case3 {
    background: #225760;
    &:after {
        background-image: linear-gradient(to top, #225760, #225a60 65%, rgba(34, 88, 97, 0));
    }
}
.case4 {
    background: #232429;
    &:after {
        background-image: linear-gradient(to bottom, rgba(59, 59, 64, 0), rgba(47, 48, 53, 0.7) 23%, rgba(34, 34, 40, 0.99) 52%, #232429);
    }
}
.case5 {
    background: #ededed;
    &:after {
        background-image: linear-gradient(to top, #ededed, #ededed 40%, rgba(#ededed, 0));
    }
}
.case4, 
.case5 {
    .case-img {
        height: 94%;
        object-fit: cover;
        object-position: center top;
        @include media-breakpoint-up(sm) {
            height: auto;
            object-fit: none;
        }
    }
}
.case6 {
    background: #dbd3bf;
}
.case7 {
    background: #24150a;
}
.usecase-video-block {
    display: none;
    left: 0;
    top: 0;
    position: absolute;
    padding: 20px;
    width: 100%;
    z-index: 11;
    .video {
        width: 100%;
    }
    .video-container {
        max-width: 480px;
        margin: 0 auto;
    }
    .video-close {
        background: $brand1;
        border: 0;
        color: #fff;
        cursor: pointer;
        height: 48px;
        top: 0;
        right: -0;
        padding: 0;
        position: absolute;
        outline: 0;
        width: 48px;
        transition: background .15s ease-in-out;
        z-index: 1;
        &:hover {
            background: rgba($brand1,.9);
        }
    }
}
.video-btn {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    padding: 0;
    height: 48px;
    position: relative;
    outline: 0;
    width: 48px;
    animation: blinker 1s cubic-bezier(.5, 0, 1, 1) infinite alternate;  
    &:hover {
        opacity: .8;
    }
    &:before {
        border-bottom: 6px solid transparent;
        border-top: 6px solid transparent;
        border-left: 12px solid #fff;
        content: '';
        left: 50%;
        top: 50%;
        margin-left: 1.5px;
        position: absolute;
        transform: translate(-50%, -50%);
    }
}
@keyframes blinker {  
    from { opacity: .4; }
    to { opacity: .8; }
}
.block3 {
    .block-title {
        max-width: 880px;
        margin: 0 auto;
        text-align: center;
        @include media-breakpoint-up(lg) {
            margin: 0;
            text-align: left;
        }
    }
    h2 {
        font-family: 'Roboto Light';
        font-weight: 300;
        margin-bottom: 64px;
        @include media-breakpoint-up(lg) {
            font-family: 'Roboto Regular';
            font-weight: 400;
            margin-bottom: 96px;
        }
    }
}
.block4 {
    background: $gray7;
    padding: 96px 0 48px;
    .text {
        margin: 0 auto;
        max-width: 992px;
    }
    .block-img {
        padding: 64px 0;
        @include media-breakpoint-up(lg) {
            padding: 96px 0;
        }
    }
}
.awards-list {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    li {
        color: $gray5;
        font-size: 12px;
        text-align: center;
        margin: 0 16px 48px;
        width: 100%;
        @media (min-width: 601px) {
            width: 234px;
        }
        .text {
            margin-top: 24px;
        }
    }
    .icon2 {
        opacity: .5;
    }
}

@import "tiny-slider/tiny-slider";

.block5 {
    h2 {
        margin-bottom: 64px;
    }
}
.product-slider-row {
    justify-content: center;
    position: relative;
}
.product-slider {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px;
    @include media-breakpoint-up(xl) {
        flex: 0 0 85%;
        max-width: 85%;
    }
    @include media-breakpoint-up(xxl) {
        flex: 0 0 75%;
        max-width: 75%;
    }
}
.item-container {
    background: $brand1;
    border-radius: 16px;
    height: 100%;
    overflow: hidden;
    padding: 250px 24px 32px;
    position: relative;
    @include media-breakpoint-up(xl) {
        padding: 336px 24px 32px;
    }
    &:after {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
    }
    img {
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        object-fit: cover;
        width: 100%;
    }
    .content {
        position: relative;
        z-index: 1;
    }
    .text {
        font-size: 16px;
    }
    .price {
        color: $green1;
        font-family: 'Roboto Medium';
        font-weight: 500;
        margin-bottom: 8px;
    }
}
.slider-controls {
    flex: 0 0 100%;
    max-width: 100%;
    outline: 0;
}
.slider-btn {
    align-items: center;
    background: $brand1;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: absolute;
    top: 100%;
    outline: 0;
    width: 40px;
    transform: translate(0,-32px);
    transition: background .1s ease-in-out;
    &:hover {
        background: rgba($brand1, .8);
    }
    @include media-breakpoint-up(xl) {
        top: 50%;
        transform: translate(0,-87px);
    }
}
.next-slide {
    right: 16px;
}
.prev-slide {
    left: 16px;
}

.tns-nav {
    margin-top: 32px;
    text-align: center;
    @include media-breakpoint-up(xl) {
        margin-top: 60px;
    }
    button {
        background: $gray7;
        border: 0;
        border-radius: 50%;
        font-size: 0;
        margin: 4px;
        height: 8px;
        padding: 0;
        outline: 0;
        width: 8px;
    }
    .tns-nav-active {
        background: $brand1;
    }
}
.block6 {
    background: $brand2;
    padding-bottom: 80px;
    .text {
        font-size: 18px;
        max-width: 642px;
        margin: 0 auto 16px;
        @include media-breakpoint-up(lg) {
            font-size: 24px;
            max-width: 725px;
            margin: 0 auto 48px;
        }
    }
}
.security-list {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 48px 0 0;
    width: 100%;
    li {
        color: rgba(#fff,.8);
        font-size: 14px;
        margin-bottom: 48px;
        text-align: center;
        padding: 0 16px;
        flex: 0 0 50%;
        max-width: 50%;
        @include media-breakpoint-up(sm) {
            flex: 0 0 232px;
            max-width: 232px;
        }
    }
    &.security-list-za {
        li {
            @include media-breakpoint-up(lg) {
                flex: 0 0 25%;
                max-width: 25%;
            }
        }
    }
    img {
        height: 50px;
    }
    .name {
        font-size: 18px;
        margin: 16px 0 8px;
    }
}
.block7 {
    background: $brand1;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    .text {
        max-width: 736px;
    }
    .container {
        position: relative;
    }
    .hub-block {
        margin-top: -32px;
        margin-bottom: 64px;
        text-align: center;
        @include media-breakpoint-up(xl) {
            right: 16px;
            margin-bottom: 0;
            margin-top: 0;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
        }
        img {
            max-width: 232px;
            @include media-breakpoint-up(md) {
                max-width: 320px;
            }
        }
    }
}
.block8 {
    padding: 0 0 48px;
    @include media-breakpoint-up(md) {
        padding: 0 0 64px;
    }
    @include media-breakpoint-up(xl) {
        padding: 128px 0 80px;
    }
    .text {
        max-width: 694px;
        margin-bottom: 64px;
    }
    .container {
        position: relative;
    }
    .video {
        max-width: 560px;
        width: 100%;
        @include media-breakpoint-up(xl) {
            max-width: 100%;
        }
    }
    .media-block {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 32px;
        text-align: center;
        @include media-breakpoint-up(sm) {
            margin-bottom: 64px;
        }
        @include media-breakpoint-up(xl) {
            flex: 0 0 41.66667%;
            max-width: 41.66667%;
            right: 0;
            padding: 0 16px;
            margin-bottom: 0;
            position: absolute;
            top: 50%;
            transform: translate(0,-50%);
        }
    }
    .content {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 16px;
        @include media-breakpoint-up(xl) {
            flex: 0 0 58.33333%;
            max-width: 58.33333%;
        }
    }
}
.block9 {
    background: $gray2;
    padding: 64px 0 96px;
    @include media-breakpoint-only(md) {
        padding: 64px 0 48px;
    }
    @include media-breakpoint-up(lg) {
        padding: 128px 0;
    }
    .content {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 16px;
        @include media-breakpoint-up(lg) {
            flex: 0 0 50%;
            max-width: 50%;
            order: 0;
        }
    }
    .icons-list {
        li {
            &:nth-child(1),
            &:nth-child(2) {
                margin-bottom: 48px;
            }
            &:nth-child(3),
            &:nth-child(4) {
                margin-bottom: 48px;
                @include media-breakpoint-up(lg) {
                    margin-bottom: 0;
                }
            }
        }
    }
    .phone-block {
        align-items: center;
        display: flex;
        justify-content: center;
        order: 0;
        margin-bottom: 64px;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        @include media-breakpoint-up(lg) {
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
            margin-bottom: 0;
            order: 1;
        }
        @include media-breakpoint-up(xxl) {
            margin-left: 8.33333%;
            flex: 0 0 25%;
            max-width: 25%;
        }
        video {
            margin: 0 50px;
            @include media-breakpoint-up(lg) {
                margin: 0;
            }
        }
        img {
            display: none;
            @include media-breakpoint-only(md) {
                display: block;
            }
        }
    }
    .video {
        width: 230px;
        @include media-breakpoint-up(lg) {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 283px;
        }
    }
    .star-block {
        display: flex;
        order: 2;
        padding: 0 16px;
        margin-top: 24px;
        @include media-breakpoint-only(md) {
            display: none;
        }
        @include media-breakpoint-up(lg) {
            flex-direction: column;
            justify-content: center;
            margin-top: 0;
        }
    }
    .AppStore {
        margin-right: 16px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 64px;
            margin-right: 0;
        }
    }
}
.block10 {
    background: $brand4;
    position: relative;
    &:before,
    &:after {
        @include media-breakpoint-up(lg) {
            content: '';
            height: 100%;
            top: 0;
            position: absolute;
            width: 50%;
        }
    }
    &:before {
        background: $brand4;
        left: 0;
    }
    &:after {
        background: #303030;
        right: 0;
    }
    .block-header {
        padding: 96px 0 0;
        position: relative;
        z-index: 1;
        @include media-breakpoint-up(lg) {
            background: $brand1;
            padding: 96px 0 64px;
        }
    }
    h2 {
        margin-bottom: 0;
    }
    .form-block__helper {
        position: relative;
        &:after {
            @include media-breakpoint-up(xl) {
                background: $brand4;
                content: '';
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                width: 58.33333%;
                z-index: 1;
            }
        }
    }
    .column-block {
        padding: 64px 16px;
        position: relative;
        z-index: 2;
        @include media-breakpoint-up(lg) {
            padding: 64px 16px 96px;
        }
    }
    .form-block {
        flex: 0 0 100%;
        max-width: 100%;
        @include media-breakpoint-up(lg) {
            flex: 0 0 50%;
            max-width: 50%;
            // flex: 0 0 58.33333%;
            // max-width: 58.33333%;
        }
        &:after {
            @include media-breakpoint-up(lg) {
                background: $brand4;
                content: '';
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                width: 100%;
                z-index: 1;
            }
        }
        .content {
            position: relative;
            z-index: 2;
        }
    }
    .config-block {
        background: #303030;
        flex: 0 0 100%;
        max-width: 100%;
        @include media-breakpoint-up(lg) {
            background: transparent;
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include media-breakpoint-up(xl) {
            //margin-left: 8.33333%;
            margin-left: 16.66667%;
            flex: 0 0 33.33333%;
            max-width: 33.33333%;
        }
        .text {
            margin-bottom: 48px;
        }
        .btn {
            width: 100%;
            @include media-breakpoint-up(sm) {
                max-width: 296px;
            }
        }
    }
    .text {
        color: $gray4;
        font-size: 20px;
    }
    .starter-block {
        align-items: center;
        background: $brand1;
        border-radius: 6px;
        display: flex;
        padding: 24px;
        max-width: 608px;
        width: 100%;
        .img {
            margin-right: 24px;
            @include media-breakpoint-up(sm) {
                margin-right: 48px;
            }
        }
        h4 {
            margin: 0;
        }
    }
    .price {
        font-size: 24px;
        line-height: 1.2;
        margin-top: 8px;
    }
    .currency {
        font-size: 16px;
    }
}

.block10-1 {
    background: #303030;
    color: #fff;
    padding: 64px 0;
    text-align: center;
    @include media-breakpoint-up(md) {
        padding: 96px 0;
    }
    @include media-breakpoint-up(lg) {
        padding: 128px 0;
    }
    .text {
        color: $gray7;
        max-width: 736px;
        margin: 0 auto 32px;
    }
    h2 {
        font-size: 32px;
        margin: 0 0 32px;
    }
}

.block10__pro {
    .block-header {
        padding: 96px 0;
        @include media-breakpoint-up(lg) {
            padding: 96px 0;
        }
    }
    h2 {
        margin-bottom: 64px;
    }
    .content {
        padding: 0 16px;
        margin: 0 auto;
        max-width: 830px;
    }
}
.block11 {
    padding: 96px 0;
    @include media-breakpoint-up(md) {
        padding: 96px 0 128px;
    }
    @include media-breakpoint-up(lg) {
        padding: 96px 0 160px;
    }
    h2 {
        margin-bottom: 64px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 96px;
        }
    }
    .row {
        @include media-breakpoint-down(md) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
@import "ajax/utilities/embed";

.videoYoutube {
    background-color: #000;
}
.video__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video__media {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: none;
}
.video__button {
    background: transparent;
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid #fff;
    color: #fff;
    height: 33px;
    left: 50%;
    top: 50%;
    padding: 0;
    position: absolute;
    opacity: .8;
    transform: translate(-50%, -50%);
    transition: opacity .2s ease;
    width: 33px;
    z-index: 1;
    @media (min-width: 481px) {
        border-width: 2px;
        height: 64px;
        width: 64px;
    }
    @media (min-width: 992px) {
        height: 168px;
        width: 168px;
    }
    svg {
        left: 50%;
        top: 50%;
        transform: translate(-6px, -50%);
        position: absolute;
        width: 12px;
        @media (min-width: 481px) {
            transform: translate(-9px, -50%);
            width: 20px;
        }
        @media (min-width: 992px) {
            transform: translate(-20px, -50%);
            width: 45px;
        }
    }
    &:hover,
    &:focus {
        opacity: 1;
    }
    &:focus {
        outline: 0;
    }
}
.video-block {
    flex: 0 0 100%;
    max-width: 100%;
}
.form {
    width: 100%;
    @include media-breakpoint-only(md) {
        max-width: 608px;
    }
}
.form-column {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px;
    @include media-breakpoint-up(xl) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.radio-column {
    padding: 0 16px;
    position: relative;
    margin-bottom: 48px;
}
.form-group {
    position: relative;
    margin-bottom: 48px;
    @include media-breakpoint-only(md) {
        max-width: 384px;
    }
}
.form-control {
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    color: #fff;
    display: block;
    font-size: 18px;
    padding: 0;
    width: 100%;
    transition: border .2s ease;
    &:focus {
        border-color: $green1;
        outline: 0;
    }
}
.plain-label {
    color: $gray4;
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
}
.select-block {
    display: block;
    position: relative;
    .style-select {
        background: transparent;
        border: 1px solid #fff;
        border-radius: 20px;
        color: #fff;
        font-family: 'Roboto Medium';
        font-weight: 500;
        height: 40px;
        padding: 0 60px 0 20px;
        appearance:none;
        width: 100%;
        &:focus {
            outline: 0;
        }
        &.error {
            & + .chevron {
                border-color: $error !important;
            }
        }
    }
    option {
        color: $brand1;
    }
    .chevron {
        align-items: center;
        border: 1px solid #fff;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        display: flex;
        justify-content: center;
        right: 0;
        top: 0;
        position: absolute;
        pointer-events: none;
        height: 40px;
        width: 40px;
        svg {
            margin-left: -2px;
        }
    }
}
.styled-checkbox {
    font-family: 'Roboto Regular';
    font-weight: 500;
    color: $gray4;
    font-size: 14px;
    display: inline-block;
    padding-left: 36px;
    cursor: pointer;
    user-select: none;
    position: relative;
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
            &:before {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 2px solid $green1;
        &:before {
            content: '';
            top: 2px;
            left: 2px;
            width: 7px;
            height: 14px;
            display: none;
            border-top: 0 solid transparent;
            border-left: 0 solid transparent;
            border-right: 2px solid $green1;
            border-bottom: 2px solid $green1;
            position: absolute;
            transform: rotateZ(37deg);
            transform-origin: 100% 100%;
        }
    }
}
.privacy-checkbox {
    margin-bottom: 24px;
}
.styled-radio {
    color: #fff;
    display: inline-block;
    font-size: 16px;
    position: relative;
    margin-bottom: 24px;
    padding-left: 36px;
    cursor: pointer;
    user-select: none;
    &:not(:last-child) {
        margin-right: 24px;
    }
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked ~ .checkmark {
            background-color: $green1;
            &:after {
                display: block;
            }
        }
    }
    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: transparent;
        border: 2px solid $green1;
        border-radius: 50%;
        &:after {
            content: "";
            position: absolute;
            display: none;
            top: 50%;
        	left: 50%;
        	width: 10px;
        	height: 10px;
        	border-radius: 50%;
        	background: $brand4;
            transform: translate(-50%, -50%);
        }
    }
}

.input-label {
    color:#fff; 
    font-size: 18px;
    font-family: 'Roboto Regular';
    font-weight: 400;
    position: absolute;
    pointer-events: none;
    left: 0;
    top: 0;
    transition: .2s ease all; 
} 
.form-control.not-empty ~ .input-label, 
.form-control:focus ~ .input-label, 
.form-control:valid ~ .input-label {
    top: -24px;
    font-size: 14px;
}
.form-control.not-empty ~ .input-label,
.form-control:valid ~ .input-label {
    color: $gray4;
}
.form-control:focus ~ .input-label {
    color: $green1;
}
.submit-btn {
    width: 100%;
    @include media-breakpoint-up(sm) {
        max-width: 296px;
    }
    &:not(:disabled) {
        cursor: pointer;
    }
    &:disabled {
        background: transparent;
        border: 1px solid $gray4;
        color: $gray4;
        &:hover,
        &:focus {
            background: transparent;
            border: 1px solid $gray4;
            color: $gray4;
        }
    }
}
.success-msg {
    position: fixed;
    top: -100px;
    left: 0;
    width: 100%;
    z-index: 1001;
    transition: top .2s ease-in-out;
    .success-content {
        background: $green1;
        font-size: 20px;
        padding: 16px 24px;
        max-width: 1000px;
        margin: 0 auto;
        text-align: center;
        width: 100%;
        @include media-breakpoint-up(lg) {
            text-align: left;
        }
    }
    &.open {
        top: 0;
    }
}
.loader {
    font-size: 0;
    text-align: center;
    width: 100%;
    @include media-breakpoint-up(sm) {
        max-width: 296px;
    }
}
.ajax-spinner {
    .inner,
    .outer {
        transform-origin: 50% 50%;
    }
    .inner {
        animation: spin .6s infinite linear;
    }
    .outer {
        animation: spin 1.5s infinite linear;
        animation-direction: reverse;
    }
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}


.footer-content {
    align-items: center;
    border-top: 1px solid rgba(#a6a6a6, .7);
    display: flex;
    flex-wrap: wrap;
    padding: 32px 16px;
    position: relative;
    width: 100%;
    @include media-breakpoint-up(md) {
        padding: 64px 16px 48px;
    }
    @media (min-width: 1550px) {
        padding: 64px 0 48px;
    }
    .footer-logo {
        font-size: 0;
        margin: 0 auto 32px;
        @include media-breakpoint-up(md) {
            margin: 0;
        }
        svg {
            width: 76px;
        }
    }
    .social-list {
        list-style: none;
        padding: 0;
        margin: 0 0 0 auto;
        li {
            display: inline-block;
            font-size: 24px;
            &:not(:last-child) {
                margin-right: 48px;
            }
            a {
                color: #383838;
                &:hover,
                &:focus {
                    color: $green1;
                }
            }
        }
    }
    .site {
        color: #1d1d1d;
        font-size: 18px;
        margin-bottom: 32px;
        @include media-breakpoint-up(md) {
            top: 64px;
            left: 50%;
            margin-bottom: 0;
            position: absolute;
            transform: translate(-50%, 0);
        }
        @include media-breakpoint-up(lg) {
            font-size: 22px;
        }
    }
    .social-list,
    .site {
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
}
.partner-footer {
    .footer-logo,
    .footer-socials {
        flex: 0 0 100%;
        max-width: 100%;
        @include media-breakpoint-up(md) {
            flex: 0 0 250px;
            max-width: 250px;
        }
    }
    .footer-contacts {
        flex: 1;
        text-align: center;
        margin-bottom: 32px;
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }
    .footer-logo {
        text-align: center;
        margin-bottom: 32px;
        @include media-breakpoint-up(md) {
            text-align: left;
            margin-bottom: 0;
        }
    }
    .footer-socials {
        text-align: right;
    }
}
.footer-copyright {
    background: $gray7;
}
.copyright-content {
    align-items: center;
    color: $gray5;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: center;
    padding: 14px 16px 0;
    @include media-breakpoint-up(md) {
        justify-content: space-between;
        flex-direction: row;
    }
    @media (min-width: 1550px) {
        padding: 14px 0 0;
    }
    a {
        color: $gray5;
        &:hover {
            color: $green1;
        }
    }
    .item {
        margin-bottom: 14px;
    }

    .footer-menu {
        align-items: center;
        list-style: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
        li {
            &:not(:last-child) {
                margin-bottom: 8px;
                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                    margin-right: 24px;
                }
            }
            a {
                color: $gray5;
                &:hover,
                &:focus {
                    color: $green1;
                }
            }
        }
    }
}
.scenario-block2 {
    //background: $brand1;
    position: relative;
    overflow: hidden;
    .scenarios-header {
        color: #fff;
        padding-top: 96px;
        text-align: center;
    }
    .scenarios-desc {
        max-width: 992px;
        margin: 0 auto;
    }
    .mobile {
        @include media-breakpoint-up(md) {
            display: none;
        }
    }
    .desktop {
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }
    .bg-video {
        display: block;
        left: 0;
        top: 0;
        min-height: 100%;
        min-width: 100%;
        position: absolute;
    }
    .container {
        position: relative;
        z-index: 1;
    }
}
.scenarios-slider {
    .slide {
        color: #fff;
        padding: 64px 0 0;
    }
    .col-12 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 16px;
    }
    .owl-stage-outer {
        padding-bottom: 128px;
    }
    h3 {
        font-size: 24px;
        margin: 0 0 32px;
    }
    .text {
        margin-top: 32px;
    }
    .owl-nav {
        position: absolute;
        z-index: 2;
        left: 0;
        padding-left: 16px;
        bottom: 64px;
        .owl-prev,
        .owl-next {
            background: $gray7;
            color: $brand1;
            transition: background .1s ease-in-out;
            &:hover {
                background: darken($gray7, 20%);
            }
        }
    }
    .owl-dots {
        align-items: center;
        display: flex;
        height: 40px;
        position: absolute;
        bottom: 64px;
        padding-left: 130px;
        z-index: 1;
        button {
            border-radius: 50%;
            height: 8px;
            width: 8px;
            background: $gray6;
            &.active {
                background: $gray7;
            }
            &:focus,
            &.focus {
                outline: 0;
            }
            &:not(:last-child) {
                margin-right: 8px;
            }
        }
    }
    .owl-nav {
        .owl-prev,
        .owl-next {
            cursor: pointer;
            height: 40px;
            width: 40px;
            &:focus,
            &.focus {
                outline: 0;
            }
            svg {
                display: inline-block;
                position: relative;
                vertical-align: middle;
            }
        }
        .owl-next {
            border-bottom-right-radius: 20px;
            border-top-right-radius: 20px;
        }
        .owl-prev {
            margin-right: 2px;
            border-bottom-left-radius: 20px;
            border-top-left-radius: 20px;
            svg {
                left: -1px;
            }
        }
    }
}
.scenario-block2__media {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px;
    @include media-breakpoint-up(md) {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    @include media-breakpoint-up(lg) {
        flex: 0 0 50%;
        max-width: 50%;
    }
}
.scenario-block2__list {
    font-family: 'Roboto Light';
    font-weight: 300;
    font-size: 18px;
    padding: 96px 16px;
    flex: 0 0 100%;
    max-width: 100%;
    @include media-breakpoint-up(md) {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
    }
    @include media-breakpoint-up(lg) {
        font-size: 24px;
        padding: 128px 16px;
        flex: 0 0 50%;
        max-width: 50%;
    }
    @include media-breakpoint-up(xl) {
        padding: 160px 16px;
        font-size: 32px;
    }
    ul {
        @include list-unstyled;
    }
    li {
        &:not(:last-child) {
            margin-bottom: 24px;
            @include media-breakpoint-up(lg) {
                margin-bottom: 48px;
            }
        }
    }
    a {
        color: $brand6;
        display: inline-block;
        @include hover {
            color: #fff;
        }
        &.active {
            @include plain-hover-focus {
                color: #fff;
            }
            .carret {
                opacity: 1;
            }
        }
    }
    .carret {
        bottom: 2px;
        height: 14px;
        position: relative;
        width: 9px;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        &:before {
            content: '';
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
            border-left: 6px solid $green1;
            left: 0;
            top: 50%;
            position: absolute;
            @include media-breakpoint-up(lg) {
                border-top-width: 7px;
                border-bottom-width: 7px;
                border-left-width: 9px;
                transform: translate(0,-2px);
            }
        }
    }
}
.scenario-tab__panel {
    color: #fff;
    padding: 48px 0;
    @include media-breakpoint-up(lg) {
        padding: 64px 0;
    }
    @include media-breakpoint-up(xl) {
        font-size: 18px;
        padding: 96px 0;
    }
    &:not(.active) {
        display: none;
    }
    .text {
        font-family: 'Roboto Light';
        font-weight: 300;
        max-width: 615px;
    }
    .video-block {
        max-width: 608px;
    }
    .scenarios-media {
        position: relative;
    }
} 
.video-block {
    position: relative;
    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }
}
.replay-block {
    display: none;
    position: absolute;
    top: 0;
    right: 16px;
    z-index: 1;
    @include media-breakpoint-up(xl) {
        right: 48px;
    }
}
.replay-btn {
    background: transparent;
    border-radius: 0;
    border: 0;
    color: #fff;
    height: 32px;
    font-size: 14px;
    padding: 0;
    display: flex;
    align-items: center;
    width: fit-content;
    transition: color .15s ease-in-out;
    &:hover {
        color: $green1;
    }
    svg {
        margin-right: 8px;
    }
}
.scenario-steps {
    @include list-unstyled;
    display: flex;
    flex-wrap: wrap;
    margin: 32px 0 16px;
    @include media-breakpoint-up(xl) {
        margin: 40px 0 24px;
    }
    li {
        margin-bottom: 8px;
        position: relative;
        &:not(:last-child) {
            margin-right: 24px;
            @include media-breakpoint-up(xl) {
                margin-right: 32px;
            }
            &:after {
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 6px solid $brand6;
                content: '';
                left: 100%;
                top: 50%;
                transform: translate(8px, -50%);
                position: absolute;
            }
        }
    }
    .scenario-steps__card {
        align-items: center;
        background: $brand4;
        border-radius: 16px;
        color: $gray4;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        padding: 12px;
        @include media-breakpoint-up(lg) {
            flex-direction: row;
            padding: 8px 16px 8px 8px;
        }
        @include media-breakpoint-up(xl) {
            padding: 8px 16px 8px 12px;
        }
    }
    .scenario-icon {
        flex: 0 0 40px;
        max-width: 40px;
        margin-bottom: 8px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            margin-right: 8px;
        }
        @include media-breakpoint-up(xl) {
            flex: 0 0 56px;
            max-width: 56px;
        }
    }
}
.block-fees {
    background: #fff;
    padding: 64px 0;
    @include media-breakpoint-up(md) {
        padding: 96px 0;
    }
    .fees-container {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 16px;
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
        }
        @include media-breakpoint-up(xl) {
            flex: 0 0 83.33333%;
            max-width: 83.33333%;
            margin-left: 8.33333%;
        }
    }
    .fees-content {
        flex: 0 0 1;
        max-width: 694px;
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            text-align: center;
        }
    }
    .fees-icon {
        @include media-breakpoint-up(md) {
            padding-right: 132px;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 200px;
        }
        @include media-breakpoint-down(sm) {
            flex: 0 0 100%;
            max-width: 100%;
            margin-bottom: 64px;
            text-align: center;
        }
    }
    img {
        width: 160px;
        min-width: 160px;
        @include media-breakpoint-up(md) {
            width: 200px;
            min-width: 200px;
        }
        @include media-breakpoint-up(xl) {
            width: 228px;
            min-width: 228px;
        }
    }
}

@import "sal/sal";
@import "owl-carousel/animate";
@import "owl-carousel/core";