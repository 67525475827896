.tns-outer {
    padding: 0 !important;
    [hidden] { display: none !important; }
    [aria-controls], [data-action] { cursor: pointer; }
  }
  .tns-slider {
    transition: all 0s;
    display: flex;
    > .tns-item {
      box-sizing: border-box;
    }
  }
  
  .tns-horizontal {
    &.tns-subpixel {
      white-space: nowrap;
      > .tns-item {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
      }
    }
    &.tns-no-subpixel {
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      > .tns-item {
        float: left;
      }
    }
    &.tns-carousel {
      &.tns-no-subpixel {
        > .tns-item {
          margin-right: -100%;
        }
      }
    }
  }
  .tns-no-calc {
    position: relative;
    left: 0;
  }
  .tns-gallery {
    position: relative;
    left: 0;
    min-height: 1px; // make sure slider container is visible
    // overflow: hidden;
    > .tns-item {
      position: absolute;
      left: -100%;
      transition: transform 0s, opacity 0s;
    }
    > .tns-slide-active {
      position: relative;
      left: auto !important;
    }
    > .tns-moving {
      -webkit-transition: all 0.25s;
      -moz-transition: all 0.25s;
      transition: all 0.25s;
    }
  }
  .tns-autowidth { display: inline-block; }
  .tns-lazy-img {
    transition: opacity 0.6s;
    opacity: 0.6;
    &.tns-complete { opacity: 1; }
  }
  .tns-ah {
    -webkit-transition: height 0s;
    -moz-transition: height 0s;
    transition: height 0s;
  }
  .tns-ovh { overflow: hidden; }
  .tns-visually-hidden { position: absolute; left: -10000em; }
  .tns-transparent { opacity: 0; visibility: hidden; }
  
  .tns-fadeIn {
    opacity: 1;
    filter: alpha(opacity=100);
    z-index: 0;
  }
  .tns-normal, .tns-fadeOut {
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: -1;
  }
  
  // *** Fix a viewport issue in initialization
  .tns-vpfix {
    white-space: nowrap;
    > div, > li {
      display: inline-block;
    }
  }
  
  // *** Detecting browser capability ***
  $width: 310px;
  $height: 10px;
  $count: 70;
  $perpage: 3;
  
  .tns-t {
    &-subp2 {
      margin: 0 auto;
      width: $width;
      position: relative;
      height: $height;
      overflow: hidden;
    }
    &-ct {
      width: (100% * $count / $perpage);
      width: -webkit-calc(100% * #{$count} / #{$perpage});
      width: -moz-calc(100% * #{$count} / #{$perpage});
      width: calc(100% * #{$count} / #{$perpage});
      position: absolute;
      right: 0;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      > div {
        width: (100% / $count);
        width: -webkit-calc(100% / #{$count});
        width: -moz-calc(100% / #{$count});
        width: calc(100% / #{$count});
        height: $height;
        float: left;
      }
    }
  }