.cookie-block {
  bottom: 20px;
  font-size: 14px;
  left: 0;
  padding: 0 16px;
  position: fixed;
  width: 100%;
  z-index: 1000; }
  .cookie-block .cookie-container {
    align-items: center;
    background-color: rgba(90, 228, 170, 0.95);
    border-radius: 8px;
    color: #242424;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 992px;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 16px; }
    @media (min-width: 576px) {
      .cookie-block .cookie-container {
        flex-direction: row;
        padding: 16px 24px; } }
    @media (min-width: 992px) {
      .cookie-block .cookie-container {
        padding: 16px 32px; } }
  .cookie-block a {
    color: #242424;
    border-color: #242424;
    font-size: 14px; }
    .cookie-block a:hover, .cookie-block a:focus {
      color: #181818; }
  .cookie-block .close-cookie {
    background: transparent;
    border: 0;
    color: #fff;
    cursor: pointer;
    height: 32px;
    width: 32px;
    text-align: center;
    position: absolute;
    top: 12px;
    font-size: 20px;
    right: 0; }
    .cookie-block .close-cookie:hover {
      color: #; }
    .cookie-block .close-cookie svg {
      width: 12px; }
  @media (min-width: 768px) {
    .cookie-block .text {
      max-width: 656px;
      margin-right: 48px; } }
  .cookie-block .accept {
    display: flex;
    align-items: center;
    margin-top: 16px; }
    @media (min-width: 576px) {
      .cookie-block .accept {
        margin-top: 0;
        flex-direction: column; } }
    @media (min-width: 768px) {
      .cookie-block .accept {
        margin-left: auto; } }
  .cookie-block .accept-block {
    order: 1; }
    @media (min-width: 576px) {
      .cookie-block .accept-block {
        order: 0;
        margin-bottom: 16px; } }
  .cookie-block .decline-block {
    margin-right: 44px;
    order: 0; }
    @media (min-width: 576px) {
      .cookie-block .decline-block {
        margin-right: 0;
        order: 1; } }
  .cookie-block .accept-cookie {
    background: #181818;
    border: 0;
    color: #fff;
    padding: 0 40px;
    min-width: 0; }
    .cookie-block .accept-cookie:hover {
      background: #272727; }
  .cookie-block .decline-cookie {
    border: 0;
    background: transparent;
    color: #3b3b3b;
    padding: 0;
    outline: 0;
    cursor: pointer; }
    .cookie-block .decline-cookie:hover {
      text-decoration: underline; }

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto Light';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url("../fonts/Roboto/roboto-300.woff2") format("woff2"), url("../fonts/Roboto/roboto-300.woff") format("woff");
  font-display: swap; }

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto Regular';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url("../fonts/Roboto/roboto-regular.woff2") format("woff2"), url("../fonts/Roboto/roboto-regular.woff") format("woff");
  font-display: swap; }

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto Medium';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url("../fonts/Roboto/roboto-500.woff2") format("woff2"), url("../fonts/Roboto/roboto-500.woff") format("woff");
  font-display: swap; }

@-ms-viewport {
  width: device-width; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-overflow-style: scrollbar; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  background-color: #EDEDED;
  color: #181818;
  display: flex;
  font-family: "Roboto Regular", sans-serif;
  font-size: 16px;
  font-weight: 400;
  flex-direction: column;
  line-height: 1.5;
  min-height: 101vh;
  margin: 0;
  text-align: left;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  @media (min-width: 992px) {
    body {
      font-size: 18px; } }

p {
  margin-top: 0;
  margin-bottom: 16px; }

ol,
ul {
  margin-top: 0;
  margin-bottom: 0; }

a {
  color: #181818;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  transition: color 0.2s ease-in-out; }
  a:hover {
    color: #fed82b;
    text-decoration: none; }

img {
  display: inline-block;
  vertical-align: middle;
  border-style: none;
  max-width: 100%; }
  img[data-src], img[data-srcset] {
    min-height: 1px; }

svg:not(:root) {
  overflow: hidden; }

.svg-icon {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -.125em;
  width: 1em; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

video {
  outline: 0; }

h2, h3, h4 {
  line-height: 1.2; }

h2 {
  font-family: 'Roboto Medium';
  font-size: 32px;
  font-weight: 500;
  margin: 0 0 32px; }
  @media (min-width: 576px) {
    h2 {
      font-size: 40px; } }
  @media (min-width: 992px) {
    h2 {
      font-size: 48px; } }

h3 {
  font-family: 'Roboto Medium';
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 32px; }
  @media (min-width: 992px) {
    h3 {
      font-size: 32px; } }

h4 {
  font-family: 'Roboto Medium';
  font-size: 24px;
  font-weight: 500;
  margin: 0 0 8px; }

h1 {
  font-family: 'Roboto Regular';
  font-size: 24px;
  font-weight: 400;
  line-height: 0.9;
  margin: 32px 0; }
  @media (min-width: 576px) {
    h1 {
      margin: 48px 0; } }
  @media (min-width: 768px) {
    h1 {
      font-size: 32px; } }
  @media (min-width: 992px) {
    h1 {
      font-size: 62px;
      margin: 64px 0; } }

.font-light {
  font-family: 'Roboto Light';
  font-weight: 300; }

.text-white {
  color: #fff; }

.btn {
  align-items: center;
  border: 0;
  border-radius: 24px;
  color: #181818;
  display: inline-flex;
  height: 40px;
  font-family: 'Roboto Medium';
  font-weight: 500;
  font-size: 14px;
  min-width: 224px;
  padding: 0;
  justify-content: center;
  white-space: nowrap;
  user-select: none;
  transition: background .2s ease-in-out;
  width: fit-content; }
  .btn:focus {
    outline: 0; }
  .btn.btn-primary {
    background-color: #; }
    .btn.btn-primary:hover, .btn.btn-primary:focus {
      background: #40e09c;
      color: #181818; }
  .btn.btn-border {
    border: 1px solid #;
    color: #;
    transition: background .2s ease-in-out, color .2s ease-in-out; }
    .btn.btn-border:hover, .btn.btn-border:focus {
      background: #;
      color: #181818; }

.container {
  width: 100%;
  max-width: 1536px;
  margin-right: auto;
  margin-left: auto;
  padding-right: 16px;
  padding-left: 16px; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-left: -16px; }
  .row.no-gutter {
    margin-right: 0;
    margin-left: 0; }

.text-center {
  text-align: center; }

.bg-gray1 {
  background: #F7F7F7; }

.bg-brand1 {
  background: #181818; }

.bg-white {
  background: #fff; }

.block-p96 {
  padding: 96px 0; }

.block-p128 {
  padding: 96px 0; }
  @media (min-width: 1200px) {
    .block-p128 {
      padding: 128px 0; } }

.mb-64 {
  margin-bottom: 64px; }

.mb-48 {
  margin-bottom: 48px; }

.icons-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 48px 0 0;
  padding: 0; }
  @media (min-width: 768px) {
    .icons-list {
      margin: 64px 0 0; } }
  .icons-list li {
    align-items: center;
    display: flex;
    font-size: 16px; }

.icons-list__type1 li {
  padding: 16px 0; }
  .icons-list__type1 li:not(:last-child) {
    margin-right: 64px; }

.icons-list__type1 .icon {
  margin-right: 16px; }

.icons-list__type2 {
  align-items: flex-start; }
  .icons-list__type2 li {
    margin-bottom: 32px;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 768px) {
      .icons-list__type2 li {
        flex-direction: column;
        flex: 0 0 224px;
        max-width: 224px;
        margin-bottom: 48px;
        justify-content: center;
        text-align: center;
        width: 224px; } }
  .icons-list__type2 .icon {
    margin-right: 24px; }
    @media (min-width: 768px) {
      .icons-list__type2 .icon {
        margin-bottom: 24px;
        margin-right: 0; } }

.icons-list__type3 li {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 48px;
  padding: 0 16px; }
  @media (min-width: 768px) {
    .icons-list__type3 li {
      flex: 0 0 50%;
      max-width: 50%; } }

.icons-list__type3 .icon {
  margin-right: 24px; }

.round-icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 88px;
  justify-content: center;
  overflow: hidden;
  width: 88px;
  flex: 0 0 88px;
  max-width: 88px; }

.header {
  background: #181818;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }
  @media (max-width: 767.98px) {
    .header {
      display: block !important; } }

.header-container {
  align-items: center;
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 0 0 16px; }
  @media (min-width: 1200px) {
    .header-container {
      padding: 0 0 0 48px; } }

.logo {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }
  .logo svg {
    fill: #fff;
    width: 76px; }

.header-btn {
  background: #;
  color: #181818;
  font-size: 14px;
  height: 60px;
  line-height: 60px;
  letter-spacing: .9px;
  margin-left: auto;
  text-transform: uppercase;
  padding: 0 16px;
  transition: background .2s ease-in-out; }
  .header-btn:hover, .header-btn:focus {
    background: #40e09c;
    color: #181818; }
  @media (min-width: 992px) {
    .header-btn {
      font-size: 18px;
      padding: 0 24px; } }

.block1 {
  align-items: center;
  background: #000;
  padding-top: 60px;
  position: relative;
  width: 100%; }
  .block1:after {
    background: rgba(0, 0, 0, 0.1);
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%; }
  @media (min-width: 576px) {
    .block1 {
      display: flex;
      min-height: 100vh;
      padding-top: 0; } }
  @media (min-width: 1200px) {
    .block1 {
      height: 100vh;
      max-height: 1080px; } }
  .block1 .block1-media {
    height: 324px;
    position: relative;
    width: 100%; }
    @media (min-width: 576px) {
      .block1 .block1-media {
        height: 100%;
        position: absolute; } }
  .block1 video {
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    position: absolute;
    width: 100%; }
  .block1 img {
    height: 100%;
    left: 0;
    top: 0;
    object-fit: cover;
    position: absolute;
    width: 100%; }
  .block1 .content {
    max-width: 752px;
    margin: 0 auto;
    padding: 48px 16px 96px;
    text-align: center;
    z-index: 1; }
    @media (min-width: 576px) {
      .block1 .content {
        padding: 100px 16px; } }
    @media (min-width: 992px) {
      .block1 .content {
        margin: 0;
        text-align: left; } }
  .block1 .logo-icon {
    width: 152px; }
    @media (min-width: 576px) {
      .block1 .logo-icon {
        width: 227px; } }
    @media (min-width: 992px) {
      .block1 .logo-icon {
        width: 350px; } }
  .block1 .text {
    font-size: 16px;
    max-width: 647px;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .block1 .text {
        font-size: 18px; } }
    @media (min-width: 992px) {
      .block1 .text {
        font-size: 20px;
        margin: 0; } }
  .block1 .btn-block {
    margin-top: 48px; }

.block2 {
  padding: 96px 0 0; }
  @media (min-width: 1200px) {
    .block2 {
      padding: 96px 0 32px; } }
  .block2 h2 {
    margin-bottom: 64px; }
    @media (min-width: 992px) {
      .block2 h2 {
        margin-bottom: 72px; } }

.promo-card {
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden; }
  @media (min-width: 1200px) {
    .promo-card {
      margin-bottom: 64px; } }
  @media (min-width: 1504px) {
    .promo-card {
      padding: 480px 48px 80px; } }
  .promo-card.open:before {
    background: rgba(24, 24, 24, 0.8);
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 10; }
  .promo-card .content {
    position: relative;
    z-index: 2; }
  .promo-card .media-block {
    display: flex;
    align-items: center;
    height: 320px;
    padding: 24px;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }
    @media (min-width: 992px) {
      .promo-card .media-block {
        height: 350px; } }
    @media (min-width: 1200px) {
      .promo-card .media-block {
        height: 400px; } }
    .promo-card .media-block img, .promo-card .media-block video {
      max-width: 95%; }
      @media (min-width: 576px) {
        .promo-card .media-block img, .promo-card .media-block video {
          max-width: 450px; } }
  .promo-card h3 {
    font-family: 'Roboto Regular';
    font-weight: 400; }

.card1 {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-direction: column; }
  @media (min-width: 768px) and (max-width: 1199.98px) {
    .card1 {
      flex-direction: row; } }
  @media (min-width: 1200px) {
    .card1 {
      padding: 400px 24px 64px;
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      flex-direction: column; } }
  @media (min-width: 1504px) {
    .card1 {
      padding: 400px 48px 64px; } }
  .card1:after {
    top: 200px;
    content: '';
    height: 264px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .card1:after {
        display: none; } }
    @media (min-width: 1200px) {
      .card1:after {
        top: 28%;
        height: 570px; } }
  .card1 .preview-block {
    height: 320px;
    position: relative;
    width: 100%; }
    @media (min-width: 768px) {
      .card1 .preview-block {
        height: auto;
        width: auto; } }
    @media (min-width: 768px) and (max-width: 1199.98px) {
      .card1 .preview-block {
        flex: 0 0 328px;
        max-width: 328px; } }
    @media (min-width: 1200px) {
      .card1 .preview-block {
        height: 400px;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0; } }
    .card1 .preview-block .case-video, .card1 .preview-block img {
      height: 100%;
      width: 100%;
      object-fit: cover; }
  .card1 .content {
    padding: 0 16px 64px; }
    @media (min-width: 768px) {
      .card1 .content {
        padding: 48px 24px; } }
    @media (min-width: 1200px) {
      .card1 .content {
        padding: 0; } }
  @media (min-width: 768px) {
    .card1 .text {
      min-height: 120px; } }
  .card1 .video-btn {
    left: 16px;
    top: -72px;
    position: absolute; }
    @media (min-width: 768px) {
      .card1 .video-btn {
        left: 0; } }
    @media (min-width: 1200px) {
      .card1 .video-btn {
        left: 0; } }

.card2 {
  padding: 320px 0 64px; }
  @media (min-width: 992px) {
    .card2 {
      padding: 350px 0 64px;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1200px) {
    .card2 {
      padding: 400px 0 64px; } }
  .card2 .content {
    padding: 0 16px; }
    @media (min-width: 768px) {
      .card2 .content {
        padding: 0 24px; } }
    @media (min-width: 1504px) {
      .card2 .content {
        padding: 0 48px; } }
  .card2:after {
    bottom: 0;
    top: auto;
    content: '';
    height: 324px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1; }
    @media (min-width: 768px) {
      .card2:after {
        top: 11%;
        height: 570px; } }
    @media (min-width: 992px) {
      .card2:after {
        height: 400px;
        top: 35%; } }
    @media (min-width: 1504px) {
      .card2:after {
        height: 570px;
        top: 28%; } }
  .card2 .video-btn {
    left: 16px;
    top: -72px;
    position: absolute; }
    @media (min-width: 768px) {
      .card2 .video-btn {
        left: 24px; } }
    @media (min-width: 1200px) {
      .card2 .video-btn {
        left: 48px; } }

@media (min-width: 768px) {
  .cases-type1 {
    margin-left: 0;
    margin-right: 0; } }

@media (min-width: 768px) {
  .cases-type2 {
    margin-top: 64px; } }

@media (min-width: 1200px) {
  .cases-type2 {
    margin-left: 0;
    margin-right: 0;
    margin-top: 0; } }

.case-img,
.case-video {
  left: 0;
  top: 0;
  position: absolute;
  width: 100%; }

.case1 {
  background: #050c15; }
  .case1:after {
    background-image: linear-gradient(to top, #050c15, #070c0f 65%, rgba(6, 22, 35, 0)); }

.case2 {
  background: #464945; }
  .case2:after {
    background-image: linear-gradient(to top, #464945, #464945 65%, rgba(70, 73, 69, 0)); }

.case3 {
  background: #225760; }
  .case3:after {
    background-image: linear-gradient(to top, #225760, #225a60 65%, rgba(34, 88, 97, 0)); }

.case4 {
  background: #232429; }
  .case4:after {
    background-image: linear-gradient(to bottom, rgba(59, 59, 64, 0), rgba(47, 48, 53, 0.7) 23%, rgba(34, 34, 40, 0.99) 52%, #232429); }

.case5 {
  background: #ededed; }
  .case5:after {
    background-image: linear-gradient(to top, #ededed, #ededed 40%, rgba(237, 237, 237, 0)); }

.case4 .case-img,
.case5 .case-img {
  height: 94%;
  object-fit: cover;
  object-position: center top; }
  @media (min-width: 576px) {
    .case4 .case-img,
    .case5 .case-img {
      height: auto;
      object-fit: none; } }

.case6 {
  background: #dbd3bf; }

.case7 {
  background: #24150a; }

.usecase-video-block {
  display: none;
  left: 0;
  top: 0;
  position: absolute;
  padding: 20px;
  width: 100%;
  z-index: 11; }
  .usecase-video-block .video {
    width: 100%; }
  .usecase-video-block .video-container {
    max-width: 480px;
    margin: 0 auto; }
  .usecase-video-block .video-close {
    background: #181818;
    border: 0;
    color: #fff;
    cursor: pointer;
    height: 48px;
    top: 0;
    right: -0;
    padding: 0;
    position: absolute;
    outline: 0;
    width: 48px;
    transition: background .15s ease-in-out;
    z-index: 1; }
    .usecase-video-block .video-close:hover {
      background: rgba(24, 24, 24, 0.9); }

.video-btn {
  background: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  padding: 0;
  height: 48px;
  position: relative;
  outline: 0;
  width: 48px;
  animation: blinker 1s cubic-bezier(0.5, 0, 1, 1) infinite alternate; }
  .video-btn:hover {
    opacity: .8; }
  .video-btn:before {
    border-bottom: 6px solid transparent;
    border-top: 6px solid transparent;
    border-left: 12px solid #fff;
    content: '';
    left: 50%;
    top: 50%;
    margin-left: 1.5px;
    position: absolute;
    transform: translate(-50%, -50%); }

@keyframes blinker {
  from {
    opacity: .4; }
  to {
    opacity: .8; } }

.block3 .block-title {
  max-width: 880px;
  margin: 0 auto;
  text-align: center; }
  @media (min-width: 992px) {
    .block3 .block-title {
      margin: 0;
      text-align: left; } }

.block3 h2 {
  font-family: 'Roboto Light';
  font-weight: 300;
  margin-bottom: 64px; }
  @media (min-width: 992px) {
    .block3 h2 {
      font-family: 'Roboto Regular';
      font-weight: 400;
      margin-bottom: 96px; } }

.block4 {
  background: #dbdbdb;
  padding: 96px 0 48px; }
  .block4 .text {
    margin: 0 auto;
    max-width: 992px; }
  .block4 .block-img {
    padding: 64px 0; }
    @media (min-width: 992px) {
      .block4 .block-img {
        padding: 96px 0; } }

.awards-list {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%; }
  .awards-list li {
    color: #5E5E5E;
    font-size: 12px;
    text-align: center;
    margin: 0 16px 48px;
    width: 100%; }
    @media (min-width: 601px) {
      .awards-list li {
        width: 234px; } }
    .awards-list li .text {
      margin-top: 24px; }
  .awards-list .icon2 {
    opacity: .5; }

.tns-outer {
  padding: 0 !important; }
  .tns-outer [hidden] {
    display: none !important; }
  .tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer; }

.tns-slider {
  transition: all 0s;
  display: flex; }
  .tns-slider > .tns-item {
    box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }
  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    transition: transform 0s, opacity 0s; }
  .tns-gallery > .tns-slide-active {
    position: relative;
    left: auto !important; }
  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  transition: opacity 0.6s;
  opacity: 0.6; }
  .tns-lazy-img.tns-complete {
    opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }
  .tns-vpfix > div, .tns-vpfix > li {
    display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: 2333.33333%;
  width: -webkit-calc(100% * 70 / 3);
  width: -moz-calc(100% * 70 / 3);
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }
  .tns-t-ct:after {
    content: '';
    display: table;
    clear: both; }
  .tns-t-ct > div {
    width: 1.42857%;
    width: -webkit-calc(100% / 70);
    width: -moz-calc(100% / 70);
    width: calc(100% / 70);
    height: 10px;
    float: left; }

.block5 h2 {
  margin-bottom: 64px; }

.product-slider-row {
  justify-content: center;
  position: relative; }

.product-slider {
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 16px; }
  @media (min-width: 1200px) {
    .product-slider {
      flex: 0 0 85%;
      max-width: 85%; } }
  @media (min-width: 1504px) {
    .product-slider {
      flex: 0 0 75%;
      max-width: 75%; } }

.item-container {
  background: #181818;
  border-radius: 16px;
  height: 100%;
  overflow: hidden;
  padding: 250px 24px 32px;
  position: relative; }
  @media (min-width: 1200px) {
    .item-container {
      padding: 336px 24px 32px; } }
  .item-container:after {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
    content: '';
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    width: 100%; }
  .item-container img {
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    object-fit: cover;
    width: 100%; }
  .item-container .content {
    position: relative;
    z-index: 1; }
  .item-container .text {
    font-size: 16px; }
  .item-container .price {
    color: #;
    font-family: 'Roboto Medium';
    font-weight: 500;
    margin-bottom: 8px; }

.slider-controls {
  flex: 0 0 100%;
  max-width: 100%;
  outline: 0; }

.slider-btn {
  align-items: center;
  background: #181818;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  position: absolute;
  top: 100%;
  outline: 0;
  width: 40px;
  transform: translate(0, -32px);
  transition: background .1s ease-in-out; }
  .slider-btn:hover {
    background: rgba(24, 24, 24, 0.8); }
  @media (min-width: 1200px) {
    .slider-btn {
      top: 50%;
      transform: translate(0, -87px); } }

.next-slide {
  right: 16px; }

.prev-slide {
  left: 16px; }

.tns-nav {
  margin-top: 32px;
  text-align: center; }
  @media (min-width: 1200px) {
    .tns-nav {
      margin-top: 60px; } }
  .tns-nav button {
    background: #dbdbdb;
    border: 0;
    border-radius: 50%;
    font-size: 0;
    margin: 4px;
    height: 8px;
    padding: 0;
    outline: 0;
    width: 8px; }
  .tns-nav .tns-nav-active {
    background: #181818; }

.block6 {
  background: #1D1D1D;
  padding-bottom: 80px; }
  .block6 .text {
    font-size: 18px;
    max-width: 642px;
    margin: 0 auto 16px; }
    @media (min-width: 992px) {
      .block6 .text {
        font-size: 24px;
        max-width: 725px;
        margin: 0 auto 48px; } }

.security-list {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 48px 0 0;
  width: 100%; }
  .security-list li {
    color: rgba(255, 255, 255, 0.8);
    font-size: 14px;
    margin-bottom: 48px;
    text-align: center;
    padding: 0 16px;
    flex: 0 0 50%;
    max-width: 50%; }
    @media (min-width: 576px) {
      .security-list li {
        flex: 0 0 232px;
        max-width: 232px; } }
  @media (min-width: 992px) {
    .security-list.security-list-za li {
      flex: 0 0 25%;
      max-width: 25%; } }
  .security-list img {
    height: 50px; }
  .security-list .name {
    font-size: 18px;
    margin: 16px 0 8px; }

.block7 {
  background: #181818;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }
  .block7 .text {
    max-width: 736px; }
  .block7 .container {
    position: relative; }
  .block7 .hub-block {
    margin-top: -32px;
    margin-bottom: 64px;
    text-align: center; }
    @media (min-width: 1200px) {
      .block7 .hub-block {
        right: 16px;
        margin-bottom: 0;
        margin-top: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); } }
    .block7 .hub-block img {
      max-width: 232px; }
      @media (min-width: 768px) {
        .block7 .hub-block img {
          max-width: 320px; } }

.block8 {
  padding: 0 0 48px; }
  @media (min-width: 768px) {
    .block8 {
      padding: 0 0 64px; } }
  @media (min-width: 1200px) {
    .block8 {
      padding: 128px 0 80px; } }
  .block8 .text {
    max-width: 694px;
    margin-bottom: 64px; }
  .block8 .container {
    position: relative; }
  .block8 .video {
    max-width: 560px;
    width: 100%; }
    @media (min-width: 1200px) {
      .block8 .video {
        max-width: 100%; } }
  .block8 .media-block {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 32px;
    text-align: center; }
    @media (min-width: 576px) {
      .block8 .media-block {
        margin-bottom: 64px; } }
    @media (min-width: 1200px) {
      .block8 .media-block {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
        right: 0;
        padding: 0 16px;
        margin-bottom: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); } }
  .block8 .content {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px; }
    @media (min-width: 1200px) {
      .block8 .content {
        flex: 0 0 58.33333%;
        max-width: 58.33333%; } }

.block9 {
  background: #EDEDED;
  padding: 64px 0 96px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .block9 {
      padding: 64px 0 48px; } }
  @media (min-width: 992px) {
    .block9 {
      padding: 128px 0; } }
  .block9 .content {
    order: 1;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px; }
    @media (min-width: 992px) {
      .block9 .content {
        flex: 0 0 50%;
        max-width: 50%;
        order: 0; } }
  .block9 .icons-list li:nth-child(1), .block9 .icons-list li:nth-child(2) {
    margin-bottom: 48px; }
  .block9 .icons-list li:nth-child(3), .block9 .icons-list li:nth-child(4) {
    margin-bottom: 48px; }
    @media (min-width: 992px) {
      .block9 .icons-list li:nth-child(3), .block9 .icons-list li:nth-child(4) {
        margin-bottom: 0; } }
  .block9 .phone-block {
    align-items: center;
    display: flex;
    justify-content: center;
    order: 0;
    margin-bottom: 64px;
    flex: 0 0 100%;
    max-width: 100%;
    position: relative; }
    @media (min-width: 992px) {
      .block9 .phone-block {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
        margin-bottom: 0;
        order: 1; } }
    @media (min-width: 1504px) {
      .block9 .phone-block {
        margin-left: 8.33333%;
        flex: 0 0 25%;
        max-width: 25%; } }
    .block9 .phone-block video {
      margin: 0 50px; }
      @media (min-width: 992px) {
        .block9 .phone-block video {
          margin: 0; } }
    .block9 .phone-block img {
      display: none; }
      @media (min-width: 768px) and (max-width: 991.98px) {
        .block9 .phone-block img {
          display: block; } }
  .block9 .video {
    width: 230px; }
    @media (min-width: 992px) {
      .block9 .video {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 283px; } }
  .block9 .star-block {
    display: flex;
    order: 2;
    padding: 0 16px;
    margin-top: 24px; }
    @media (min-width: 768px) and (max-width: 991.98px) {
      .block9 .star-block {
        display: none; } }
    @media (min-width: 992px) {
      .block9 .star-block {
        flex-direction: column;
        justify-content: center;
        margin-top: 0; } }
  .block9 .AppStore {
    margin-right: 16px; }
    @media (min-width: 992px) {
      .block9 .AppStore {
        margin-bottom: 64px;
        margin-right: 0; } }

.block10 {
  background: #242424;
  position: relative; }
  @media (min-width: 992px) {
    .block10:before, .block10:after {
      content: '';
      height: 100%;
      top: 0;
      position: absolute;
      width: 50%; } }
  .block10:before {
    background: #242424;
    left: 0; }
  .block10:after {
    background: #303030;
    right: 0; }
  .block10 .block-header {
    padding: 96px 0 0;
    position: relative;
    z-index: 1; }
    @media (min-width: 992px) {
      .block10 .block-header {
        background: #181818;
        padding: 96px 0 64px; } }
  .block10 h2 {
    margin-bottom: 0; }
  .block10 .form-block__helper {
    position: relative; }
    @media (min-width: 1200px) {
      .block10 .form-block__helper:after {
        background: #242424;
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 58.33333%;
        z-index: 1; } }
  .block10 .column-block {
    padding: 64px 16px;
    position: relative;
    z-index: 2; }
    @media (min-width: 992px) {
      .block10 .column-block {
        padding: 64px 16px 96px; } }
  .block10 .form-block {
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 992px) {
      .block10 .form-block {
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 992px) {
      .block10 .form-block:after {
        background: #242424;
        content: '';
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        z-index: 1; } }
    .block10 .form-block .content {
      position: relative;
      z-index: 2; }
  .block10 .config-block {
    background: #303030;
    flex: 0 0 100%;
    max-width: 100%; }
    @media (min-width: 992px) {
      .block10 .config-block {
        background: transparent;
        flex: 0 0 50%;
        max-width: 50%; } }
    @media (min-width: 1200px) {
      .block10 .config-block {
        margin-left: 16.66667%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%; } }
    .block10 .config-block .text {
      margin-bottom: 48px; }
    .block10 .config-block .btn {
      width: 100%; }
      @media (min-width: 576px) {
        .block10 .config-block .btn {
          max-width: 296px; } }
  .block10 .text {
    color: #ADADAD;
    font-size: 20px; }
  .block10 .starter-block {
    align-items: center;
    background: #181818;
    border-radius: 6px;
    display: flex;
    padding: 24px;
    max-width: 608px;
    width: 100%; }
    .block10 .starter-block .img {
      margin-right: 24px; }
      @media (min-width: 576px) {
        .block10 .starter-block .img {
          margin-right: 48px; } }
    .block10 .starter-block h4 {
      margin: 0; }
  .block10 .price {
    font-size: 24px;
    line-height: 1.2;
    margin-top: 8px; }
  .block10 .currency {
    font-size: 16px; }

.block10-1 {
  background: #303030;
  color: #fff;
  padding: 64px 0;
  text-align: center; }
  @media (min-width: 768px) {
    .block10-1 {
      padding: 96px 0; } }
  @media (min-width: 992px) {
    .block10-1 {
      padding: 128px 0; } }
  .block10-1 .text {
    color: #dbdbdb;
    max-width: 736px;
    margin: 0 auto 32px; }
  .block10-1 h2 {
    font-size: 32px;
    margin: 0 0 32px; }

.block10__pro .block-header {
  padding: 96px 0; }
  @media (min-width: 992px) {
    .block10__pro .block-header {
      padding: 96px 0; } }

.block10__pro h2 {
  margin-bottom: 64px; }

.block10__pro .content {
  padding: 0 16px;
  margin: 0 auto;
  max-width: 830px; }

.block11 {
  padding: 96px 0; }
  @media (min-width: 768px) {
    .block11 {
      padding: 96px 0 128px; } }
  @media (min-width: 992px) {
    .block11 {
      padding: 96px 0 160px; } }
  .block11 h2 {
    margin-bottom: 64px; }
    @media (min-width: 992px) {
      .block11 h2 {
        margin-bottom: 96px; } }
  @media (max-width: 991.98px) {
    .block11 .row {
      margin-left: 0;
      margin-right: 0; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.videoYoutube {
  background-color: #000; }

.video__link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.video__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none; }

.video__button {
  background: transparent;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #fff;
  height: 33px;
  left: 50%;
  top: 50%;
  padding: 0;
  position: absolute;
  opacity: .8;
  transform: translate(-50%, -50%);
  transition: opacity .2s ease;
  width: 33px;
  z-index: 1; }
  @media (min-width: 481px) {
    .video__button {
      border-width: 2px;
      height: 64px;
      width: 64px; } }
  @media (min-width: 992px) {
    .video__button {
      height: 168px;
      width: 168px; } }
  .video__button svg {
    left: 50%;
    top: 50%;
    transform: translate(-6px, -50%);
    position: absolute;
    width: 12px; }
    @media (min-width: 481px) {
      .video__button svg {
        transform: translate(-9px, -50%);
        width: 20px; } }
    @media (min-width: 992px) {
      .video__button svg {
        transform: translate(-20px, -50%);
        width: 45px; } }
  .video__button:hover, .video__button:focus {
    opacity: 1; }
  .video__button:focus {
    outline: 0; }

.video-block {
  flex: 0 0 100%;
  max-width: 100%; }

.form {
  width: 100%; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .form {
      max-width: 608px; } }

.form-column {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 16px; }
  @media (min-width: 1200px) {
    .form-column {
      flex: 0 0 50%;
      max-width: 50%; } }

.radio-column {
  padding: 0 16px;
  position: relative;
  margin-bottom: 48px; }

.form-group {
  position: relative;
  margin-bottom: 48px; }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .form-group {
      max-width: 384px; } }

.form-control {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  color: #fff;
  display: block;
  font-size: 18px;
  padding: 0;
  width: 100%;
  transition: border .2s ease; }
  .form-control:focus {
    border-color: #;
    outline: 0; }

.plain-label {
  color: #ADADAD;
  display: block;
  font-size: 16px;
  margin-bottom: 16px; }

.select-block {
  display: block;
  position: relative; }
  .select-block .style-select {
    background: transparent;
    border: 1px solid #fff;
    border-radius: 20px;
    color: #fff;
    font-family: 'Roboto Medium';
    font-weight: 500;
    height: 40px;
    padding: 0 60px 0 20px;
    appearance: none;
    width: 100%; }
    .select-block .style-select:focus {
      outline: 0; }
    .select-block .style-select.error + .chevron {
      border-color: #ff6b6b !important; }
  .select-block option {
    color: #181818; }
  .select-block .chevron {
    align-items: center;
    border: 1px solid #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    display: flex;
    justify-content: center;
    right: 0;
    top: 0;
    position: absolute;
    pointer-events: none;
    height: 40px;
    width: 40px; }
    .select-block .chevron svg {
      margin-left: -2px; }

.styled-checkbox {
  font-family: 'Roboto Regular';
  font-weight: 500;
  color: #ADADAD;
  font-size: 14px;
  display: inline-block;
  padding-left: 36px;
  cursor: pointer;
  user-select: none;
  position: relative; }
  .styled-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .styled-checkbox input:checked ~ .checkmark:before {
      display: block; }
  .styled-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid #; }
    .styled-checkbox .checkmark:before {
      content: '';
      top: 2px;
      left: 2px;
      width: 7px;
      height: 14px;
      display: none;
      border-top: 0 solid transparent;
      border-left: 0 solid transparent;
      border-right: 2px solid #;
      border-bottom: 2px solid #;
      position: absolute;
      transform: rotateZ(37deg);
      transform-origin: 100% 100%; }

.privacy-checkbox {
  margin-bottom: 24px; }

.styled-radio {
  color: #fff;
  display: inline-block;
  font-size: 16px;
  position: relative;
  margin-bottom: 24px;
  padding-left: 36px;
  cursor: pointer;
  user-select: none; }
  .styled-radio:not(:last-child) {
    margin-right: 24px; }
  .styled-radio input {
    position: absolute;
    opacity: 0;
    cursor: pointer; }
    .styled-radio input:checked ~ .checkmark {
      background-color: #; }
      .styled-radio input:checked ~ .checkmark:after {
        display: block; }
  .styled-radio .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 2px solid #;
    border-radius: 50%; }
    .styled-radio .checkmark:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #242424;
      transform: translate(-50%, -50%); }

.input-label {
  color: #fff;
  font-size: 18px;
  font-family: 'Roboto Regular';
  font-weight: 400;
  position: absolute;
  pointer-events: none;
  left: 0;
  top: 0;
  transition: .2s ease all; }

.form-control.not-empty ~ .input-label,
.form-control:focus ~ .input-label,
.form-control:valid ~ .input-label {
  top: -24px;
  font-size: 14px; }

.form-control.not-empty ~ .input-label,
.form-control:valid ~ .input-label {
  color: #ADADAD; }

.form-control:focus ~ .input-label {
  color: #; }

.submit-btn {
  width: 100%; }
  @media (min-width: 576px) {
    .submit-btn {
      max-width: 296px; } }
  .submit-btn:not(:disabled) {
    cursor: pointer; }
  .submit-btn:disabled {
    background: transparent;
    border: 1px solid #ADADAD;
    color: #ADADAD; }
    .submit-btn:disabled:hover, .submit-btn:disabled:focus {
      background: transparent;
      border: 1px solid #ADADAD;
      color: #ADADAD; }

.success-msg {
  position: fixed;
  top: -100px;
  left: 0;
  width: 100%;
  z-index: 1001;
  transition: top .2s ease-in-out; }
  .success-msg .success-content {
    background: #;
    font-size: 20px;
    padding: 16px 24px;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    width: 100%; }
    @media (min-width: 992px) {
      .success-msg .success-content {
        text-align: left; } }
  .success-msg.open {
    top: 0; }

.loader {
  font-size: 0;
  text-align: center;
  width: 100%; }
  @media (min-width: 576px) {
    .loader {
      max-width: 296px; } }

.ajax-spinner .inner,
.ajax-spinner .outer {
  transform-origin: 50% 50%; }

.ajax-spinner .inner {
  animation: spin .6s infinite linear; }

.ajax-spinner .outer {
  animation: spin 1.5s infinite linear;
  animation-direction: reverse; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(359deg); } }

.footer-content {
  align-items: center;
  border-top: 1px solid rgba(166, 166, 166, 0.7);
  display: flex;
  flex-wrap: wrap;
  padding: 32px 16px;
  position: relative;
  width: 100%; }
  @media (min-width: 768px) {
    .footer-content {
      padding: 64px 16px 48px; } }
  @media (min-width: 1550px) {
    .footer-content {
      padding: 64px 0 48px; } }
  .footer-content .footer-logo {
    font-size: 0;
    margin: 0 auto 32px; }
    @media (min-width: 768px) {
      .footer-content .footer-logo {
        margin: 0; } }
    .footer-content .footer-logo svg {
      width: 76px; }
  .footer-content .social-list {
    list-style: none;
    padding: 0;
    margin: 0 0 0 auto; }
    .footer-content .social-list li {
      display: inline-block;
      font-size: 24px; }
      .footer-content .social-list li:not(:last-child) {
        margin-right: 48px; }
      .footer-content .social-list li a {
        color: #383838; }
        .footer-content .social-list li a:hover, .footer-content .social-list li a:focus {
          color: #; }
  .footer-content .site {
    color: #1d1d1d;
    font-size: 18px;
    margin-bottom: 32px; }
    @media (min-width: 768px) {
      .footer-content .site {
        top: 64px;
        left: 50%;
        margin-bottom: 0;
        position: absolute;
        transform: translate(-50%, 0); } }
    @media (min-width: 992px) {
      .footer-content .site {
        font-size: 22px; } }
  @media (max-width: 767.98px) {
    .footer-content .social-list,
    .footer-content .site {
      flex: 0 0 100%;
      max-width: 100%;
      text-align: center; } }

.partner-footer .footer-logo,
.partner-footer .footer-socials {
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .partner-footer .footer-logo,
    .partner-footer .footer-socials {
      flex: 0 0 250px;
      max-width: 250px; } }

.partner-footer .footer-contacts {
  flex: 1;
  text-align: center;
  margin-bottom: 32px; }
  @media (min-width: 768px) {
    .partner-footer .footer-contacts {
      margin-bottom: 0; } }
  .partner-footer .footer-contacts ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .partner-footer .footer-contacts li:not(:last-child) {
    margin-bottom: 4px; }

.partner-footer .footer-logo {
  text-align: center;
  margin-bottom: 32px; }
  @media (min-width: 768px) {
    .partner-footer .footer-logo {
      text-align: left;
      margin-bottom: 0; } }

.partner-footer .footer-socials {
  text-align: right; }

.footer-copyright {
  background: #dbdbdb; }

.copyright-content {
  align-items: center;
  color: #5E5E5E;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  justify-content: center;
  padding: 14px 16px 0; }
  @media (min-width: 768px) {
    .copyright-content {
      justify-content: space-between;
      flex-direction: row; } }
  @media (min-width: 1550px) {
    .copyright-content {
      padding: 14px 0 0; } }
  .copyright-content a {
    color: #5E5E5E; }
    .copyright-content a:hover {
      color: #; }
  .copyright-content .item {
    margin-bottom: 14px; }
  .copyright-content .footer-menu {
    align-items: center;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 14px; }
    @media (min-width: 768px) {
      .copyright-content .footer-menu {
        flex-direction: row; } }
    .copyright-content .footer-menu li:not(:last-child) {
      margin-bottom: 8px; }
      @media (min-width: 768px) {
        .copyright-content .footer-menu li:not(:last-child) {
          margin-bottom: 0;
          margin-right: 24px; } }
    .copyright-content .footer-menu li a {
      color: #5E5E5E; }
      .copyright-content .footer-menu li a:hover, .copyright-content .footer-menu li a:focus {
        color: #; }

.scenario-block2 {
  position: relative;
  overflow: hidden; }
  .scenario-block2 .scenarios-header {
    color: #fff;
    padding-top: 96px;
    text-align: center; }
  .scenario-block2 .scenarios-desc {
    max-width: 992px;
    margin: 0 auto; }
  @media (min-width: 768px) {
    .scenario-block2 .mobile {
      display: none; } }
  @media (max-width: 767.98px) {
    .scenario-block2 .desktop {
      display: none; } }
  .scenario-block2 .bg-video {
    display: block;
    left: 0;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    position: absolute; }
  .scenario-block2 .container {
    position: relative;
    z-index: 1; }

.scenarios-slider .slide {
  color: #fff;
  padding: 64px 0 0; }

.scenarios-slider .col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 16px; }

.scenarios-slider .owl-stage-outer {
  padding-bottom: 128px; }

.scenarios-slider h3 {
  font-size: 24px;
  margin: 0 0 32px; }

.scenarios-slider .text {
  margin-top: 32px; }

.scenarios-slider .owl-nav {
  position: absolute;
  z-index: 2;
  left: 0;
  padding-left: 16px;
  bottom: 64px; }
  .scenarios-slider .owl-nav .owl-prev,
  .scenarios-slider .owl-nav .owl-next {
    background: #dbdbdb;
    color: #181818;
    transition: background .1s ease-in-out; }
    .scenarios-slider .owl-nav .owl-prev:hover,
    .scenarios-slider .owl-nav .owl-next:hover {
      background: #a8a8a8; }

.scenarios-slider .owl-dots {
  align-items: center;
  display: flex;
  height: 40px;
  position: absolute;
  bottom: 64px;
  padding-left: 130px;
  z-index: 1; }
  .scenarios-slider .owl-dots button {
    border-radius: 50%;
    height: 8px;
    width: 8px;
    background: #383838; }
    .scenarios-slider .owl-dots button.active {
      background: #dbdbdb; }
    .scenarios-slider .owl-dots button:focus, .scenarios-slider .owl-dots button.focus {
      outline: 0; }
    .scenarios-slider .owl-dots button:not(:last-child) {
      margin-right: 8px; }

.scenarios-slider .owl-nav .owl-prev,
.scenarios-slider .owl-nav .owl-next {
  cursor: pointer;
  height: 40px;
  width: 40px; }
  .scenarios-slider .owl-nav .owl-prev:focus, .scenarios-slider .owl-nav .owl-prev.focus,
  .scenarios-slider .owl-nav .owl-next:focus,
  .scenarios-slider .owl-nav .owl-next.focus {
    outline: 0; }
  .scenarios-slider .owl-nav .owl-prev svg,
  .scenarios-slider .owl-nav .owl-next svg {
    display: inline-block;
    position: relative;
    vertical-align: middle; }

.scenarios-slider .owl-nav .owl-next {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px; }

.scenarios-slider .owl-nav .owl-prev {
  margin-right: 2px;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px; }
  .scenarios-slider .owl-nav .owl-prev svg {
    left: -1px; }

.scenario-block2__media {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 16px; }
  @media (min-width: 768px) {
    .scenario-block2__media {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; } }
  @media (min-width: 992px) {
    .scenario-block2__media {
      flex: 0 0 50%;
      max-width: 50%; } }

.scenario-block2__list {
  font-family: 'Roboto Light';
  font-weight: 300;
  font-size: 18px;
  padding: 96px 16px;
  flex: 0 0 100%;
  max-width: 100%; }
  @media (min-width: 768px) {
    .scenario-block2__list {
      flex: 0 0 33.3333%;
      max-width: 33.3333%; } }
  @media (min-width: 992px) {
    .scenario-block2__list {
      font-size: 24px;
      padding: 128px 16px;
      flex: 0 0 50%;
      max-width: 50%; } }
  @media (min-width: 1200px) {
    .scenario-block2__list {
      padding: 160px 16px;
      font-size: 32px; } }
  .scenario-block2__list ul {
    margin: 0;
    padding-left: 0;
    list-style: none; }
  .scenario-block2__list li:not(:last-child) {
    margin-bottom: 24px; }
    @media (min-width: 992px) {
      .scenario-block2__list li:not(:last-child) {
        margin-bottom: 48px; } }
  .scenario-block2__list a {
    color: #787878;
    display: inline-block; }
    .scenario-block2__list a:hover {
      color: #fff; }
    .scenario-block2__list a.active, .scenario-block2__list a.active:hover, .scenario-block2__list a.active:focus {
      color: #fff; }
    .scenario-block2__list a.active .carret {
      opacity: 1; }
  .scenario-block2__list .carret {
    bottom: 2px;
    height: 14px;
    position: relative;
    width: 9px;
    opacity: 0;
    transition: opacity .2s ease-in-out; }
    .scenario-block2__list .carret:before {
      content: '';
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid #;
      left: 0;
      top: 50%;
      position: absolute; }
      @media (min-width: 992px) {
        .scenario-block2__list .carret:before {
          border-top-width: 7px;
          border-bottom-width: 7px;
          border-left-width: 9px;
          transform: translate(0, -2px); } }

.scenario-tab__panel {
  color: #fff;
  padding: 48px 0; }
  @media (min-width: 992px) {
    .scenario-tab__panel {
      padding: 64px 0; } }
  @media (min-width: 1200px) {
    .scenario-tab__panel {
      font-size: 18px;
      padding: 96px 0; } }
  .scenario-tab__panel:not(.active) {
    display: none; }
  .scenario-tab__panel .text {
    font-family: 'Roboto Light';
    font-weight: 300;
    max-width: 615px; }
  .scenario-tab__panel .video-block {
    max-width: 608px; }
  .scenario-tab__panel .scenarios-media {
    position: relative; }

.video-block {
  position: relative; }
  .video-block video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; }

.replay-block {
  display: none;
  position: absolute;
  top: 0;
  right: 16px;
  z-index: 1; }
  @media (min-width: 1200px) {
    .replay-block {
      right: 48px; } }

.replay-btn {
  background: transparent;
  border-radius: 0;
  border: 0;
  color: #fff;
  height: 32px;
  font-size: 14px;
  padding: 0;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: color .15s ease-in-out; }
  .replay-btn:hover {
    color: #; }
  .replay-btn svg {
    margin-right: 8px; }

.scenario-steps {
  margin: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 32px 0 16px; }
  @media (min-width: 1200px) {
    .scenario-steps {
      margin: 40px 0 24px; } }
  .scenario-steps li {
    margin-bottom: 8px;
    position: relative; }
    .scenario-steps li:not(:last-child) {
      margin-right: 24px; }
      @media (min-width: 1200px) {
        .scenario-steps li:not(:last-child) {
          margin-right: 32px; } }
      .scenario-steps li:not(:last-child):after {
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 6px solid #787878;
        content: '';
        left: 100%;
        top: 50%;
        transform: translate(8px, -50%);
        position: absolute; }
  .scenario-steps .scenario-steps__card {
    align-items: center;
    background: #242424;
    border-radius: 16px;
    color: #ADADAD;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    padding: 12px; }
    @media (min-width: 992px) {
      .scenario-steps .scenario-steps__card {
        flex-direction: row;
        padding: 8px 16px 8px 8px; } }
    @media (min-width: 1200px) {
      .scenario-steps .scenario-steps__card {
        padding: 8px 16px 8px 12px; } }
  .scenario-steps .scenario-icon {
    flex: 0 0 40px;
    max-width: 40px;
    margin-bottom: 8px; }
    @media (min-width: 992px) {
      .scenario-steps .scenario-icon {
        margin-bottom: 0;
        margin-right: 8px; } }
    @media (min-width: 1200px) {
      .scenario-steps .scenario-icon {
        flex: 0 0 56px;
        max-width: 56px; } }

.block-fees {
  background: #fff;
  padding: 64px 0; }
  @media (min-width: 768px) {
    .block-fees {
      padding: 96px 0; } }
  .block-fees .fees-container {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 16px; }
    @media (min-width: 768px) {
      .block-fees .fees-container {
        flex-wrap: nowrap; } }
    @media (min-width: 1200px) {
      .block-fees .fees-container {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
        margin-left: 8.33333%; } }
  .block-fees .fees-content {
    flex: 0 0 1;
    max-width: 694px; }
    @media (max-width: 767.98px) {
      .block-fees .fees-content {
        flex: 0 0 100%;
        max-width: 100%;
        text-align: center; } }
  @media (min-width: 768px) {
    .block-fees .fees-icon {
      padding-right: 132px; } }
  @media (min-width: 1200px) {
    .block-fees .fees-icon {
      padding-right: 200px; } }
  @media (max-width: 767.98px) {
    .block-fees .fees-icon {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 64px;
      text-align: center; } }
  .block-fees img {
    width: 160px;
    min-width: 160px; }
    @media (min-width: 768px) {
      .block-fees img {
        width: 200px;
        min-width: 200px; } }
    @media (min-width: 1200px) {
      .block-fees img {
        width: 228px;
        min-width: 228px; } }

/**
 * Settings
 */
/**
 * Easings
 */
/**
 * Core
 */
[data-sal] {
  transition-duration: 0.6s;
  transition-delay: 0s; }

[data-sal][data-sal-duration='200'] {
  transition-duration: .2s; }

[data-sal][data-sal-delay='200'] {
  transition-delay: .2s; }

[data-sal][data-sal-delay='400'] {
  transition-delay: .4s; }

[data-sal][data-sal-delay='600'] {
  transition-delay: .6s; }

/**
 * Animations
 */
[data-sal|='fade'] {
  opacity: 0;
  transition-property: opacity; }

[data-sal|='fade'].sal-animate,
body.sal-disabled [data-sal|='fade'] {
  opacity: 1; }

[data-sal='slide-up'] {
  opacity: 0;
  transform: translateY(40px);
  transition-property: opacity, transform; }

[data-sal|='slide-up'].sal-animate,
body.sal-disabled [data-sal|='slide-up'] {
  opacity: 1;
  transform: translateY(0); }

/*
 *  Owl Carousel - Animate Plugin
 */
/*****************************************/
/* Soft Scale */
/*****************************************/
.owl-animate .owl-stage {
  transform: none !important;
  width: 100% !important;
  position: relative;
  border: none;
  overflow: hidden;
  display: block; }

.owl-animate .owl-item {
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 10; }
  .owl-animate .owl-item:not(:first-child) {
    position: absolute;
    top: 0 !important;
    left: 0 !important; }
  .owl-animate .owl-item.active {
    z-index: 20;
    opacity: 1; }

.owl-animate .animated {
  animation-duration: 0.4s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out; }
  .owl-animate .animated .animate-element {
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out; }

.owl-animate .owl-animated-in {
  z-index: 0; }

.owl-animate .owl-animated-out {
  z-index: 1; }

.fadeOutNext,
.fadeOutPrev {
  transition: opacity .4s ease; }
  .fadeOutNext .animate-element,
  .fadeOutPrev .animate-element {
    animation-name: slidefadeOut;
    animation-duration: 0.4s;
    animation-fill-mode: forwards; }

.fadeInNext .animate-element {
  animation-name: fadeInRight; }

.fadeInPrev .animate-element {
  animation-name: fadeInLeft; }

.slider.owl-carousel .animated {
  animation-duration: 300ms; }

/* .app-slider.owl-carousel {
   .animated {
	   animation-duration: 200ms;
   }
   .anim {
	   animation-duration: 600ms;
	   animation-fill-mode: both;
   }
} */
.about-slider.owl-carousel .animated {
  animation-duration: 200ms; }

.about-slider.owl-carousel .anim {
  animation-duration: 600ms;
  animation-fill-mode: both; }

@keyframes slidefadeOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 0; } }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-30px, 0, 0); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(30px, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-30px, 0, 0); }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }

.fadeInLeft {
  animation-name: fadeInLeft; }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }
